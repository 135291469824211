import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { retry, createApi } from '@reduxjs/toolkit/query/react'
import { type AxiosRequestConfig, type AxiosError, isAxiosError } from 'axios'
import { format, parseISO } from 'date-fns'
import { toast } from 'sonner'
import config from '@/config'
import type {
  EmailEventResponseType,
  EmailEventTypes,
  EmailOrderingDirection,
  EmailOrderingType,
  ITagResponse,
  MarketingCampaignEmail,
  MarketingCampaignError,
  MarketingCampaignEvent,
  MarketingCampaignLinkType,
  MarketingCampaignStatus,
  MarketingEmailCampaignReport,
  MarketingEmailCampaignType,
  MarketingSmsCampaignError,
  MarketingSMSCampaignEvent,
  MarketingSMSCampaignMessage,
  MarketingSMSCampaignReport,
  MarketingSmsCampaignType,
  PartialCampaignType,
  SmsEventTypes,
} from '@/state/entities/marketing/campaigns/campaigns.types'
import type {
  RowsOverview,
  VoucherCodeImportRowsType,
  VoucherCodeImportType,
  VoucherCodeType,
  VoucherPerformanceReportType,
  VoucherStatusType,
  VoucherType,
} from '@/state/entities/vouchers/vouchers.types'
import type {
  DomainType,
  EmailAddressType,
} from '@/state/entities/marketing/domains/domains.types'
import type {
  EmailEventType,
  EmailType,
} from '@/state/entities/marketing/emails/emails.types'
import type {
  RevisionOwnerType,
  RevisionType,
  SimpleRevisionType,
} from '@/state/entities/marketing/revisions/revisions.types'
import type { ServiceSettingsType } from '@/state/entities/marketing/service-settings/service-settings.types'
import type {
  SmsSenderType,
  SmsType,
} from '@/state/entities/marketing/sms/sms.types'
import type {
  MarketingEmailTemplateType,
  PartialMarketingEmailTemplateType,
  TemplatePresetType,
} from '@/state/entities/marketing/templates/templates.types'
import type {
  ServiceCursorPaginatedResponseType,
  ServicePaginatedResponseType,
} from '@/connect-types/backend/service'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import { buildUrl } from '@/utils/common'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { post } from '@/utils/api'
import { type VerificationEmail } from '@/pages/[org_id]/marketing/email/verification-emails/types'
import { shouldBailout } from '../helpers'
import type {
  EmailMarketingReportType,
  TagResourceType,
  UserEmailEvent,
} from './types/email.marketing'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      if ((method ?? '').toUpperCase() !== 'GET') {
        ServiceErrorHandler(axiosError)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const serviceApi = createApi({
  reducerPath: 'service',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.service }), {
    maxRetries: 5,
  }),

  tagTypes: [
    'PresetEmailTemplates',
    'EmailMarketingCampaigns',
    'EmailMarketingTemplates',
    'EmailMarketingRevisions',
    'EmailMarketingCampaignReport',
    'EmailDomains',
    'EmailAddresses',
    'SMSMessages',
    'SMSSenders',
    'SMSCampaigns',
    'ServiceSettings',
    'Vouchers',
    'ServiceTags',
  ],

  endpoints: (build) => ({
    getScheduleBusyTimes: build.query<
      { date: Date; busyness: number }[],
      { orgId: string; date: Date }
    >({
      query: ({ orgId, date }) => ({
        url: buildUrl(
          `/${orgId}/marketing/email-campaigns/schedule-busy-times`,
          {
            date: date.toISOString(),
          }
        ),
        method: 'GET',
      }),
      transformResponse: (
        res: {
          date: string
          busyness: number
        }[]
      ) => {
        return res.map(({ date, busyness }) => ({
          date: parseISO(date),
          busyness,
        }))
      },
    }),
    getTags: build.query<
      ITagResponse<{ name: string; count: number }>,
      {
        orgId: string
        resource_type: TagResourceType
        name_like?: string
      }
    >({
      query: ({ orgId, resource_type = 'EmailCampaign', name_like = '' }) => ({
        url: buildUrl(`/${orgId}/tags/distinct-by-resource-type`, {
          resource_type,
          name_like,
        }),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'ServiceTags', id: 'LIST' }],
    }),
    deleteTag: build.mutation<
      { message: string },
      { orgId: string; resource_type: TagResourceType; tagId: string }
    >({
      query: ({ orgId, resource_type = 'EmailCampaign', tagId }) => ({
        url: buildUrl(`/${orgId}/tags/${tagId}`, {
          resource_type,
        }),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        { type: 'ServiceTags', id: 'LIST' },
        { type: 'EmailMarketingCampaigns', id: 'LIST' },
        { type: 'EmailMarketingTemplates', id: 'LIST' },
      ],
    }),
    createTag: build.mutation<
      { message: string },
      {
        orgId: string
        resource_type: TagResourceType
        name: string
        value: string | null
        resource_id: string
      }
    >({
      query: ({
        orgId,
        resource_type = 'EmailCampaign',
        name,
        value,
        resource_id,
      }) => ({
        url: `/${orgId}/tags`,
        method: 'POST',
        data: {
          resource_type,
          name,
          ...(value && { value }),
          resource_id,
        },
      }),
      invalidatesTags: (result) => [
        { type: 'ServiceTags', id: 'LIST' },

        { type: 'EmailMarketingCampaigns', id: 'LIST' },
        { type: 'EmailMarketingTemplates', id: 'LIST' },
      ],
    }),
    presetTemplates: build.query<Omit<TemplatePresetType, 'design'>[], unknown>(
      {
        query: () => ({
          url: `/unlayer-templates`,
          method: 'get',
        }),
        providesTags: ['PresetEmailTemplates'],
      }
    ),
    presetTemplate: build.query<TemplatePresetType, number>({
      query: (id) => ({
        url: `/unlayer-templates/${id}`,
        method: 'get',
      }),
    }),

    getVerificationEmails: build.query<
      ServiceCursorPaginatedResponseType<VerificationEmail>,
      { orgId: string; cursor: string; limit?: number }
    >({
      query: ({ orgId, cursor, limit }) => ({
        url: buildUrl(`/${orgId}/verification-emails`, {
          cursor,
          query_type: 'cursor',
          limit,
        }),
        method: 'get',
      }),
    }),

    getAuditEmails: build.query<
      ServiceCursorPaginatedResponseType<EmailType>,
      {
        orgId: string
        page: string
        to_email_contains?: string
        category?: string
        limit?: number
      }
    >({
      query: ({ orgId, to_email_contains, page, limit }) => ({
        url: buildUrl(`/${orgId}/emails`, {
          cursor: to_email_contains !== '' ? null : page,
          to_email_contains,
          query_type: 'cursor',
          limit,
        }),
        method: 'get',
      }),
    }),

    getAuditEmail: build.query<
      EmailType,
      {
        orgId: string
        email_id: string
      }
    >({
      query: ({ orgId, email_id }) => ({
        url: `/${orgId}/emails/${email_id}`,
        method: 'get',
      }),
      transformResponse: (response: { data: EmailType }) => {
        return response.data
      },
    }),
    getAuditEmailEvents: build.query<
      ServicePaginatedResponseType<EmailEventType>,
      {
        orgId: string
        email_id: string
        page: number
      }
    >({
      query: ({ orgId, email_id, page }) => ({
        url: `/${orgId}/emails/${email_id}/events?page=${page}`,
        method: 'get',
      }),
    }),
    getEmailMarketingReport: build.query<
      MarketingEmailCampaignReport,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/marketing/email-campaigns/reports`,
        method: 'get',
      }),
    }),

    getEmailMarketingCampaigns: build.query<
      ServicePaginatedResponseType<PartialCampaignType>,
      {
        orgId: string
        name?: string
        searchBy?: 'name' | 'tag'
        page: number
        order_by?: EmailOrderingType
        order_by_direction?: EmailOrderingDirection
        status_in?: MarketingCampaignStatus[] | undefined
        tag_name?: string
      }
    >({
      query: ({ orgId, ...rest }) => {
        const {
          name,
          searchBy,
          page,
          order_by,
          order_by_direction,
          status_in,
          tag_name,
        } = rest
        const isTag = searchBy === 'tag'
        let tag_value: string, hasTagValue: boolean

        if (name && name !== '') {
          hasTagValue = name.includes(':')
          tag_value = isTag && hasTagValue ? name.split(':')[1].trim() : null
        }
        const url = buildUrl(`/${orgId}/marketing/email-campaigns`, {
          name: name && isTag ? null : name,
          has_tag_name: tag_name,
          has_tag_value: name && isTag ? tag_value : null,
          page,
          order_by,
          order_by_direction,
          status_in: (status_in ?? []).length === 6 ? null : status_in,
        })

        return {
          url,
          method: 'get',
        }
      },
      providesTags: (items) => [
        ...items.data.map((item) => ({
          id: item.id,
          type: 'EmailMarketingCampaigns' as const,
        })),
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    getMarketingEmailCampaign: build.query<
      MarketingEmailCampaignType,
      { orgId: string; campaignId: string }
    >({
      query: ({ orgId, campaignId }) => ({
        url: `/${orgId}/marketing/email-campaigns/${campaignId}`,
        method: 'get',
      }),
      transformResponse: (res: {
        email_campaign: MarketingEmailCampaignType
      }) => {
        return res.email_campaign
      },
      transformErrorResponse: (error) => {
        return error
      },
      providesTags: (item) => [
        { id: item?.id, type: 'EmailMarketingCampaigns' },
      ],
    }),
    updateMarketingEmailCampaign: build.mutation<
      MarketingEmailCampaignType,
      MarketingEmailCampaignType
    >({
      query: (campaign) => {
        const camp =
          campaign.segment_id === 'all'
            ? { ...campaign, segment_id: null }
            : campaign
        return {
          url: `/${campaign.organisation_id}/marketing/email-campaigns/${campaign.id}`,
          method: 'PUT',
          data: camp,
        }
      },
      transformResponse: (res: {
        email_campaign: MarketingEmailCampaignType
      }) => {
        return res.email_campaign
      },
      invalidatesTags: (item) =>
        item ? [{ id: item.id, type: 'EmailMarketingCampaigns' }] : [],
    }),
    createMarketingEmailCampaign: build.mutation<
      MarketingEmailCampaignType,
      Partial<MarketingEmailCampaignType>
    >({
      query: (campaign) => ({
        url: `/${campaign.organisation_id}/marketing/email-campaigns`,
        method: 'post',
        data: {
          ...campaign,
          spend_per_head_currency: campaign.spend_per_head_currency ?? 'GBP',
        },
      }),
      transformResponse: (res: {
        email_campaign: MarketingEmailCampaignType
      }) => {
        toast.success(`${res.email_campaign.name} created`)
        return res.email_campaign
      },
      invalidatesTags: () => [{ id: 'LIST', type: 'EmailMarketingCampaigns' }],
    }),
    deleteMarketingEmailCampaign: build.mutation<unknown, PartialCampaignType>({
      query: (campaign) => ({
        url: `/${campaign.organisation_id}/marketing/email-campaigns/${campaign.id}`,
        method: 'delete',
      }),
      transformResponse: (res, _, { name }) => {
        toast.success(`${name} deleted`)
        return res
      },
      invalidatesTags: () => [{ id: 'LIST', type: 'EmailMarketingCampaigns' }],
    }),

    /**
     *
     */
    requestApprovalFormMarketingEmailCampaign: build.mutation<
      { message: string },
      PartialCampaignType
    >({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/request-approval`,
        method: 'post',
      }),
      transformResponse: (res: { message: string }) => {
        toast.success(res.message)
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    approvalFormMarketingEmailCampaign: build.mutation<
      { message: string },
      PartialCampaignType
    >({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/approve-campaign`,
        method: 'post',
      }),
      transformResponse: (res: { message: string }) => {
        toast.success(res.message)
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),

    sendMarketingEmailTemplatePreview: build.mutation<
      { message: string; text: string },
      {
        organisation_id: string
        id: string
        from_id: string
        to_email: string
        to_name: string
      }
    >({
      query: ({ organisation_id, id, from_id, to_email, to_name }) => ({
        url: `/${organisation_id}/marketing/email-templates/${id}/send-preview`,
        data: {
          from_id,
          to_email,
          to_name,
        },
        method: 'post',
      }),
    }),

    sendMarketingEmailCampaignPreview: build.mutation<
      { message: string; text: string },
      {
        organisation_id: string
        id: string
        from_id: string
        to_email: string
        to_name: string
      }
    >({
      query: ({ organisation_id, id, from_id, to_email, to_name }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/send-preview`,
        data: {
          from_id,
          to_email,
          to_name,
        },
        method: 'post',
      }),
    }),

    sendMarketingEmailCampaign: build.mutation<unknown, PartialCampaignType>({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/send-campaign`,
        method: 'post',
      }),
      transformResponse: (res, _2, { name }) => {
        toast.success(`Campaign ${name} is sending`)
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    scheduleMarketingEmailCampaign: build.mutation<
      unknown,
      MarketingEmailCampaignType
    >({
      query: ({ organisation_id, id, scheduled_to_send_at }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/schedule-campaign`,
        method: 'post',
        data: { scheduled_to_send_at },
      }),

      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    cancelScheduleMarketingEmailCampaign: build.mutation<
      unknown,
      PartialCampaignType
    >({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/cancel-scheduled-campaign`,
        method: 'post',
        data: {},
      }),
      transformResponse: (res) => {
        toast.success('Campaign schedule cancelled')
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    cancelMarketingEmailCampaign: build.mutation<unknown, PartialCampaignType>({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/cancel-campaign`,
        method: 'post',
        data: {},
      }),
      transformResponse: (res) => {
        toast.success('Campaign cancelled')
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    startMarketingEmailAutomationCampaign: build.mutation<
      unknown,
      PartialCampaignType
    >({
      query: ({ organisation_id, id, started_at }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/start-automated-campaign`,
        method: 'post',
        data: { started_at },
      }),
      transformResponse: (res) => {
        toast.success('Automation campaign started')
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    stopMarketingEmailAutomationCampaign: build.mutation<
      unknown,
      PartialCampaignType
    >({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-campaigns/${id}/pause-automated-campaign`,
        method: 'post',
        data: {},
      }),
      transformResponse: (res) => {
        toast.success('Automation campaign stopped')
        return res
      },
      invalidatesTags: (_unused, _2, { id }) => [
        { id, type: 'EmailMarketingCampaigns' },
        { id: 'LIST', type: 'EmailMarketingCampaigns' },
      ],
    }),
    getMarketingEmailCampaignReport: build.query<
      MarketingEmailCampaignReport,
      { orgId: string; campaignId: string }
    >({
      query: ({ orgId, campaignId }) => ({
        url: `/${orgId}/marketing/email-campaigns/${campaignId}/reports`,
        method: 'get',
      }),
    }),
    getMarketingEmailCampaignErrorReport: build.query<
      ServicePaginatedResponseType<MarketingCampaignError>,
      {
        orgId: string
        campaign_id: string
        page: number
      }
    >({
      query: ({ orgId, campaign_id, page }) => ({
        url: buildUrl(
          `/${orgId}/marketing/email-campaigns/${campaign_id}/errors`,
          { page }
        ),
        method: 'get',
      }),
    }),
    getMarketingEmailCampaignReportLinks: build.query<
      MarketingCampaignLinkType[],
      { orgId: string; campaignId: string }
    >({
      query: ({ orgId, campaignId }) => ({
        url: `/${orgId}/marketing/email-campaigns/${campaignId}/clicked-links`,
        method: 'get',
      }),
      transformResponse: (res: {
        clicked_links: MarketingCampaignLinkType[]
      }) => {
        return res.clicked_links.filter(
          (item) => !item.click_url.startsWith('https://stampede.ai')
        )
      },
    }),
    getMarketingEmailCampaignSentTo: build.query<
      ServiceCursorPaginatedResponseType<MarketingCampaignEmail>,
      { orgId: string; campaignId: string; page: string; limit: number }
    >({
      query: ({ orgId, campaignId, limit, page }) => ({
        url: buildUrl(
          `/${orgId}/marketing/email-campaigns/${campaignId}/emails`,
          { cursor: page, limit, query_type: 'cursor' }
        ),
        method: 'get',
      }),
    }),
    getMarketingEmailCampaignEventTypes: build.query<
      EmailEventResponseType,
      { orgId: string; campaignId: string }
    >({
      query: ({ orgId, campaignId }) => ({
        url: `/${orgId}/marketing/email-campaigns/${campaignId}/event-types`,
        method: 'get',
      }),
      transformResponse: (res: { event_types: EmailEventResponseType }) => {
        return res.event_types
      },
    }),
    getMarketingEmailCampaignEvents: build.query<
      ServiceCursorPaginatedResponseType<MarketingCampaignEvent>,
      {
        orgId: string
        campaignId: string
        page: string
        limit?: number
        event_type?: EmailEventTypes
      }
    >({
      query: ({ orgId, campaignId, ...rest }) => ({
        url: buildUrl(
          `/${orgId}/marketing/email-campaigns/${campaignId}/events`,
          { ...rest, query_type: 'cursor' }
        ),
        method: 'get',
      }),
    }),

    getEmailMarketingTemplates: build.query<
      ServicePaginatedResponseType<PartialMarketingEmailTemplateType>,
      { orgId: string; page: number; tag_name?: string }
    >({
      query: ({ orgId, page, tag_name }) => ({
        url: buildUrl(`/${orgId}/marketing/email-templates`, {
          page,
          ...(tag_name && { has_tag_name: tag_name }),
        }),
        method: 'get',
      }),
      providesTags: (items) => [
        ...items.data.map((item) => ({
          id: item.id,
          type: 'EmailMarketingTemplates' as const,
        })),
        { id: 'LIST', type: 'EmailMarketingTemplates' },
      ],
    }),
    getEmailMarketingTemplate: build.query<
      MarketingEmailTemplateType,
      { orgId: string; templateId: string }
    >({
      query: ({ orgId, templateId }) => ({
        url: `/${orgId}/marketing/email-templates/${templateId}`,
        method: 'get',
      }),
      providesTags: (item) => [
        { id: item.id, type: 'EmailMarketingTemplates' },
      ],
      transformResponse: (item: {
        email_template: MarketingEmailTemplateType
      }) => {
        return item.email_template
      },
    }),
    createEmailMarketingTemplate: build.mutation<
      MarketingEmailTemplateType,
      { orgId: string; template: Partial<MarketingEmailTemplateType> }
    >({
      query: ({ orgId, template }) => ({
        url: `/${orgId}/marketing/email-templates`,
        method: 'post',
        data: template,
      }),
      invalidatesTags: [{ id: 'LIST', type: 'EmailMarketingTemplates' }],
      transformResponse: (item: {
        email_template: MarketingEmailTemplateType
      }) => {
        return item.email_template
      },
    }),
    updateEmailMarketingTemplate: build.mutation<
      MarketingEmailTemplateType,
      { orgId: string; template: MarketingEmailTemplateType }
    >({
      query: ({ orgId, template }) => ({
        url: `/${orgId}/marketing/email-templates/${template.id}`,
        method: 'put',
        data: template,
      }),
      invalidatesTags: (arg) => [
        { id: arg.id, type: 'EmailMarketingTemplates' },
      ],
      transformResponse: (item: {
        email_template: MarketingEmailTemplateType
      }) => {
        return item.email_template
      },
    }),
    deleteEmailMarketingTemplate: build.mutation<
      unknown,
      { orgId: string; template: PartialMarketingEmailTemplateType }
    >({
      query: ({ orgId, template }) => ({
        url: `/${orgId}/marketing/email-templates/${template.id}`,
        method: 'delete',
        data: template,
      }),
      invalidatesTags: [{ id: 'LIST', type: 'EmailMarketingTemplates' }],
      transformResponse: (item: unknown, _, { template }) => {
        return item
      },
    }),
    getEmailMarketingRevisions: build.query<
      ServicePaginatedResponseType<SimpleRevisionType>,
      {
        orgId: string
        owner_type: RevisionOwnerType
        owner_id?: string
        page: number // look at building a ServicePaginatedRequestType
      }
    >({
      query: ({ orgId, ...rest }) => ({
        url: buildUrl(`/${orgId}/revisions`, rest),
        method: 'get',
      }),
      providesTags: () => [{ id: 'LIST', type: 'EmailMarketingRevisions' }],
    }),
    getEmailMarketingRevision: build.query<
      RevisionType,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/${orgId}/revisions/${id}`,
        method: 'get',
      }),
      providesTags: (item) => [
        { id: item.id, type: 'EmailMarketingRevisions' },
      ],
    }),
    getEmailDomains: build.query<
      ServicePaginatedResponseType<DomainType>,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/marketing/domains`,
        method: 'get',
      }),
      providesTags: () => [{ id: 'LIST', type: 'EmailDomains' }],
    }),
    getEmailDomain: build.query<
      DomainType,
      {
        orgId: string
        domain_id: string
      }
    >({
      query: ({ orgId, domain_id }) => ({
        url: `/${orgId}/marketing/domains/${domain_id}`,
        method: 'get',
      }),
      transformResponse: (item: { domain: DomainType }) => {
        return item.domain
      },
      providesTags: ({ id }) => [{ id, type: 'EmailDomains' }],
    }),

    getEmailAddresses: build.query<
      ServicePaginatedResponseType<EmailAddressType>,
      {
        orgId: string
        page: number
        domain_id?: string
      }
    >({
      query: ({ orgId, domain_id }) => ({
        url: buildUrl(`/${orgId}/marketing/email-addresses`, {
          domain_id,
          limit: 50,
        }),
        method: 'get',
      }),
      providesTags: () => [{ id: 'LIST', type: 'EmailAddresses' }],
    }),
    getEmailAddress: build.query<
      EmailAddressType,
      {
        orgId: string
        id: string
      }
    >({
      query: ({ orgId, id }) => ({
        url: `/${orgId}/marketing/email-addresses/${id}`,
        method: 'get',
      }),
      providesTags: (item) =>
        item ? [{ id: item.id, type: 'EmailAddresses' }] : [],
      transformResponse: (item: { email_address: EmailAddressType }) => {
        return item.email_address
      },
    }),
    deleteEmailAddress: build.mutation<unknown, EmailAddressType>({
      query: ({ id, organisation_id }) => ({
        url: `/${organisation_id}/marketing/email-addresses/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => [
        { id: 'LIST', type: 'EmailAddresses' },
        { id: 'LIST', type: 'EmailDomains' },
      ],
    }),
    createEmailAddress: build.mutation<
      EmailAddressType,
      {
        orgId: string
        email: string
        name: string
      }
    >({
      query: ({ orgId, name, email }) => ({
        url: `/${orgId}/marketing/email-addresses`,
        method: 'post',
        data: { email, name },
      }),
      invalidatesTags: () => [
        { id: 'LIST', type: 'EmailAddresses' },
        { id: 'LIST', type: 'EmailDomains' },
      ],
      transformResponse: (item: { email_address: EmailAddressType }) => {
        toast.success('Email address created')
        return item.email_address
      },
    }),
    updateEmailAddress: build.mutation<EmailAddressType, EmailAddressType>({
      query: ({ organisation_id, name, id }) => ({
        url: `/${organisation_id}/marketing/email-addresses/${id}`,
        method: 'put',
        data: { name },
      }),
      invalidatesTags: () => [{ id: 'LIST', type: 'EmailAddresses' }],
      transformResponse: (item: { email_address: EmailAddressType }) => {
        toast.success('Email address updated')
        return item.email_address
      },
    }),
    updatePrimaryEmailAddress: build.mutation<
      EmailAddressType,
      EmailAddressType
    >({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-addresses/${id}/make-primary`,
        method: 'put',
        data: {},
      }),
      invalidatesTags: (_item) => [
        { id: 'LIST', type: 'EmailAddresses' },
        { id: 'LIST', type: 'EmailDomains' },
      ],
      transformResponse: (item: { email_address: EmailAddressType }) => {
        toast.success('Email address moved to primary')
        return item.email_address
      },
    }),
    resendValidationEmailAddress: build.mutation<
      EmailAddressType,
      EmailAddressType
    >({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-addresses/${id}/resend-verification-email`,
        method: 'put',
        data: {},
      }),
      invalidatesTags: (item) => [{ id: item.id, type: 'EmailAddresses' }],
      transformResponse: (item: { email_address: EmailAddressType }) => {
        toast.success(`Validation email sent to ${item.email_address.email}`)
        return item.email_address
      },
    }),
    refreshEmailDomain: build.mutation<DomainType, DomainType>({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/domains/${id}/refresh`,
        method: 'put',
        data: {},
      }),
      invalidatesTags: (_item) => [
        { id: 'LIST', type: 'EmailAddresses' },
        { id: 'LIST', type: 'EmailDomains' },
      ],
      transformResponse: (item: { domain: DomainType }) => {
        toast.success(`DNS status updated ${item.domain.name}`)
        return item.domain
      },
    }),
    refreshEmailAddress: build.mutation<EmailAddressType, EmailAddressType>({
      query: ({ organisation_id, id }) => ({
        url: `/${organisation_id}/marketing/email-addresses/${id}/refresh`,
        method: 'put',
        data: {},
      }),
      invalidatesTags: (_item) => [
        { id: 'LIST', type: 'EmailAddresses' },
        { id: 'LIST', type: 'EmailDomains' },
      ],
      transformResponse: (item: { email_address: EmailAddressType }) => {
        return item.email_address
      },
    }),
    getUserEmailEvents: build.query<
      ServicePaginatedResponseType<UserEmailEvent>,
      {
        orgId: string
        email: string
        cursor: string | null
      }
    >({
      query: ({ orgId, email, cursor }) => {
        return {
          url: buildUrl(`/${orgId}/email-events/for-email`, {
            email,
            cursor,
          }),
          method: 'get',
        }
      },
    }),

    /*
    SMS START
    */

    getSmsMessages: build.query<
      ServicePaginatedResponseType<SmsType>,
      { orgId: string; limit?: number; page: string; to_contains?: string }
    >({
      query: ({ orgId, to_contains, page, limit }) => ({
        url: buildUrl(`/${orgId}/marketing/sms-messages`, {
          cursor: to_contains !== '' ? null : page,
          to_contains,
          query_type: 'cursor',
          limit,
        }),
        method: 'get',
      }),
      providesTags: () => [{ id: 'LIST', type: 'SMSMessages' }],
    }),

    getSmsSenders: build.query<
      ServicePaginatedResponseType<SmsSenderType>,
      { orgId: string; page: number }
    >({
      query: ({ orgId, page }) => ({
        url: buildUrl(`/${orgId}/marketing/sms-senders`, {
          page,
        }),
        method: 'get',
      }),
      providesTags: () => [{ id: 'LIST', type: 'SMSSenders' }],
    }),
    getSmsSender: build.query<SmsSenderType, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `/${orgId}/marketing/sms-senders/${id}`,
        method: 'get',
      }),
      providesTags: (item) => [{ id: item.id, type: 'SMSSenders' }],
      transformResponse: (item: { sms_senders: SmsSenderType }) => {
        return item.sms_senders
      },
    }),
    updateSmsSender: build.mutation<SmsSenderType, SmsSenderType>({
      query: ({ organisation_id, ...sender }) => ({
        url: `/${organisation_id}/marketing/sms-senders/${sender.id}`,
        method: 'put',
        data: sender,
      }),
      invalidatesTags: (item) => [
        { id: item.id, type: 'SMSSenders' },
        { id: 'LIST', type: 'SMSSenders' },
      ],
      transformResponse: (item: { sms_sender: SmsSenderType }) => {
        toast.success('SMS sender updated')
        return item.sms_sender
      },
    }),
    createSmsSender: build.mutation<SmsSenderType, Partial<SmsSenderType>>({
      query: ({ organisation_id, ...sender }) => ({
        url: `/${organisation_id}/marketing/sms-senders`,
        method: 'post',
        data: sender,
      }),
      invalidatesTags: (item) => [
        { id: item.id, type: 'SMSSenders' },
        { id: 'LIST', type: 'SMSSenders' },
      ],
      transformResponse: (item: { sms_sender: SmsSenderType }) => {
        toast.success('SMS sender created')
        return item.sms_sender
      },
    }),
    deleteSmsSender: build.mutation<unknown, SmsSenderType>({
      query: ({ organisation_id, ...sender }) => ({
        url: `/${organisation_id}/marketing/sms-senders/${sender.id}`,
        method: 'delete',
        data: sender,
      }),
      invalidatesTags: (_un1, _un2, item) => [
        { id: item.id, type: 'SMSSenders' },
        { id: 'LIST', type: 'SMSSenders' },
      ],
      transformResponse: (item: unknown) => {
        toast.success('SMS sender deleted')
        return item
      },
    }),

    getSmsMarketingReport: build.query<
      MarketingSMSCampaignReport,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/marketing/sms-campaigns/reports`,
        method: 'get',
      }),
    }),

    getSmsMarketingCampaign: build.query<
      MarketingSmsCampaignType,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        url: `/${orgId}/marketing/sms-campaigns/${id}`,
        method: 'get',
      }),
      providesTags: (item) =>
        item ? [{ type: 'SMSCampaigns', id: item.id }] : [],
      transformResponse: (item: { sms_campaign: MarketingSmsCampaignType }) => {
        return item.sms_campaign
      },
    }),
    getSmsMarketingCampaigns: build.query<
      ServicePaginatedResponseType<MarketingSmsCampaignType>,
      { orgId: string; page: number; name?: string }
    >({
      query: (props) => {
        const { orgId, name = '', page } = props

        const tagValueCheck = name.includes(':')
        const has_tag_name = !tagValueCheck ? name : name.split(':')[0].trim()
        const has_tag_value = tagValueCheck ? name.split(':')[1].trim() : null

        return {
          method: 'get',
          url: buildUrl(`/${orgId}/marketing/sms-campaigns`, {
            page: !name ? page : null,
            has_tag_name,
            has_tag_value,
          }),
        }
      },
      providesTags: () => [{ type: 'SMSCampaigns', id: 'LIST' }],
    }),
    createSmsMarketingCampaign: build.mutation<
      MarketingSmsCampaignType,
      Partial<MarketingSmsCampaignType>
    >({
      query: ({ organisation_id, ...rest }) => ({
        url: `/${organisation_id}/marketing/sms-campaigns`,
        method: 'post',
        data: {
          ...rest,
          spend_per_head_currency: rest.spend_per_head_currency ?? 'GBP',
        },
      }),
      invalidatesTags: () => [{ type: 'SMSCampaigns', id: 'LIST' }],
      transformResponse: (item: { sms_campaign: MarketingSmsCampaignType }) => {
        toast.success('SMS campaign created')
        return item.sms_campaign
      },
    }),
    updateSmsMarketingCampaign: build.mutation<
      MarketingSmsCampaignType,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        if (rest.segment_id === 'all') {
          rest.segment_id = null
        }
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}`,
          method: 'put',
          data: rest,
        }
      },
      invalidatesTags: (item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: { sms_campaign: MarketingSmsCampaignType }) => {
        toast.success('SMS campaign updated')
        return item.sms_campaign
      },
    }),
    deleteSmsMarketingCampaign: build.mutation<
      unknown,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}`,
          method: 'delete',
        }
      },
      invalidatesTags: () => [{ type: 'SMSCampaigns', id: 'LIST' }],
      transformResponse: (item: unknown) => {
        toast.success('SMS campaign deleted')
        return item
      },
    }),
    requestApprovalSmsMarketingCampaign: build.mutation<
      { message: string },
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/request-approval`,
          method: 'post',
          data: {},
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: { message: string }) => {
        toast.success(item.message)
        return item
      },
    }),
    approveSmsMarketingCampaign: build.mutation<
      { message: string },
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/approve-campaign`,
          method: 'post',
          data: {},
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: { message: string }) => {
        toast.success(item.message)
        return item
      },
    }),
    sendSmsMarketingCampaign: build.mutation<unknown, MarketingSmsCampaignType>(
      {
        query: ({ organisation_id, ...rest }) => {
          return {
            url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/send-campaign`,
            method: 'post',
            data: {},
          }
        },
        invalidatesTags: (_item, _2, item) => [
          { type: 'SMSCampaigns', id: item.id },
          { type: 'SMSCampaigns', id: 'LIST' },
        ],
        transformResponse: (item: unknown) => {
          toast.success(`SMS campaign sent`)
          return item
        },
      }
    ),
    scheduleSmsMarketingCampaign: build.mutation<
      unknown,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, scheduled_to_send_at, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/schedule-campaign`,
          method: 'post',
          data: {
            scheduled_to_send_at,
          },
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: unknown) => {
        toast.success(`SMS campaign scheduled`)
        return item
      },
    }),
    cancelScheduleSmsMarketingCampaign: build.mutation<
      unknown,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/cancel-scheduled-campaign`,
          method: 'post',
          data: {},
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: unknown) => {
        toast.success(`SMS campaign schedule cancelled`)
        return item
      },
    }),
    cancelSmsMarketingCampaign: build.mutation<
      unknown,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/cancel-campaign`,
          method: 'post',
          data: {},
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: unknown) => {
        toast.success(`SMS campaign cancelled`)
        return item
      },
    }),
    startAutomationSmsMarketingCampaign: build.mutation<
      unknown,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, started_at, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/start-automated-campaign`,
          method: 'post',
          data: { started_at },
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: unknown) => {
        toast.success(`SMS campaign automation started`)
        return item
      },
    }),
    stopAutomationSmsMarketingCampaign: build.mutation<
      unknown,
      MarketingSmsCampaignType
    >({
      query: ({ organisation_id, ...rest }) => {
        return {
          url: `/${organisation_id}/marketing/sms-campaigns/${rest.id}/pause-automated-campaign`,
          method: 'post',
          data: {},
        }
      },
      invalidatesTags: (_item, _2, item) => [
        { type: 'SMSCampaigns', id: item.id },
        { type: 'SMSCampaigns', id: 'LIST' },
      ],
      transformResponse: (item: unknown) => {
        toast.success(`SMS campaign automation stopped`)
        return item
      },
    }),

    getSmsMarketingCampaignReport: build.query<
      MarketingSMSCampaignReport,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        url: `/${orgId}/marketing/sms-campaigns/${id}/reports`,
        method: 'get',
      }),
    }),
    getSmsMarketingCampaignMessagesReport: build.query<
      ServicePaginatedResponseType<MarketingSMSCampaignMessage>,
      {
        orgId: string
        campaign_id: string
        page: number
        event_type?: SmsEventTypes
      }
    >({
      query: ({ orgId, campaign_id, page, event_type }) => ({
        url: buildUrl(
          `/${orgId}/marketing/sms-campaigns/${campaign_id}/sms-messages`,
          { page, event_type }
        ),
        method: 'get',
      }),
    }),
    getSmsMarketingCampaignErrorReport: build.query<
      ServicePaginatedResponseType<MarketingSmsCampaignError>,
      {
        orgId: string
        campaign_id: string
        page: number
      }
    >({
      query: ({ orgId, campaign_id, page }) => ({
        url: buildUrl(
          `/${orgId}/marketing/sms-campaigns/${campaign_id}/errors`,
          { page }
        ),
        method: 'get',
      }),
    }),
    getSmsMarketingCampaignEventsReport: build.query<
      ServicePaginatedResponseType<MarketingSMSCampaignEvent>,
      {
        orgId: string
        campaign_id: string
        page: number
        event_type?: SmsEventTypes
      }
    >({
      query: ({ orgId, campaign_id, page, event_type }) => ({
        url: buildUrl(
          `/${orgId}/marketing/sms-campaigns/${campaign_id}/events`,
          { page, event_type }
        ),
        method: 'get',
      }),
    }),

    getGsmConversion: build.mutation<
      { message: string },
      {
        orgId: string
        message: string
      }
    >({
      query: ({ orgId, message }) => ({
        url: `/${orgId}/marketing/sms-campaigns/convert-to-gsm`,
        method: 'post',
        data: {
          message,
        },
      }),
    }),

    /*
    SMS END
    */

    /**
     * ----
     * VOUCHER QUERIES START
     * ---
     */
    getVouchers: build.query<
      ServiceCursorPaginatedResponseType<VoucherType>,
      { orgId: string; page: string; limit: number }
    >({
      query: ({ orgId, page, limit }) => ({
        url: buildUrl(`/${orgId}/vouchers`, {
          cursor: page,
          limit,
          query_type: 'cursor',
        }),
        method: 'get',
      }),
      providesTags: [
        {
          id: 'LIST',
          type: 'Vouchers',
        },
      ],
    }),
    getVoucher: build.query<VoucherType, { orgId: string; id: string }>({
      query: ({ orgId, id }) => ({
        url: `/${orgId}/vouchers/${id}`,
        method: 'get',
      }),

      providesTags: (item) => [{ id: item.id, type: 'Vouchers' }],
      transformResponse(data: { voucher: VoucherType }) {
        return data.voucher
      },
    }),
    getVoucherImports: build.query<
      ServiceCursorPaginatedResponseType<VoucherCodeImportType>,
      { orgId: string; voucher_id: string; page: string; limit: number }
    >({
      query: ({ orgId, voucher_id, page, limit }) => ({
        url: buildUrl(`/${orgId}/voucher-code-imports`, {
          voucher_id,
          cursor: page,
          limit,
          query_type: 'cursor',
        }),
        method: 'get',
      }),
      providesTags: ['Vouchers'],
    }),
    getVoucherImportsRows: build.query<
      ServiceCursorPaginatedResponseType<VoucherCodeImportRowsType>,
      { orgId: string; voucher_id: string; page: string; limit: number }
    >({
      query: ({ orgId, voucher_id, page, limit }) => ({
        url: buildUrl(`/${orgId}/voucher-code-imports/${voucher_id}/rows`, {
          cursor: page,
          limit,
          query_type: 'cursor',
        }),
        method: 'get',
      }),

      providesTags: ['Vouchers'],
    }),
    getVoucherCodes: build.query<
      ServiceCursorPaginatedResponseType<VoucherCodeType>,
      { orgId: string; code: string; page: string; limit: number }
    >({
      query: ({ orgId, code, page, limit }) => ({
        url: buildUrl(`/${orgId}/vouchers/${code}/codes`, {
          cursor: page,
          limit,
          query_type: 'cursor',
        }),
        method: 'get',
      }),

      providesTags: ['Vouchers'],
    }),
    getVoucherStatus: build.query<
      ServicePaginatedResponseType<VoucherStatusType>,
      { orgId: string; voucherId: string }
    >({
      query: ({ orgId, voucherId }) => ({
        url: `/${orgId}/vouchers/search-by-code?code=${voucherId}`,
        method: 'get',
      }),
      providesTags: ['Vouchers'],
      transformErrorResponse: (error) => {
        return error
      },
    }),
    getVoucherPerformanceReport: build.query<
      VoucherPerformanceReportType,
      { orgId: string; voucherId: string }
    >({
      query: ({ orgId, voucherId }) => ({
        url: `/${orgId}/vouchers/${voucherId}/performance-report`,
        method: 'get',
      }),
      providesTags: ['Vouchers'],
    }),
    createVoucher: build.mutation<
      VoucherType,
      { orgId: string; voucher: Partial<VoucherType> }
    >({
      query: ({ orgId, voucher }) => ({
        url: `/${orgId}/vouchers`,
        method: 'POST',
        data: {
          ...voucher,
        },
      }),

      invalidatesTags: () => [{ id: 'LIST', type: 'Vouchers' }],
    }),
    updateVoucher: build.mutation<
      VoucherType,
      { orgId: string; voucher: Partial<VoucherType> }
    >({
      query: ({ orgId, voucher }) => ({
        url: `/${orgId}/vouchers/${voucher.id}`,
        method: 'PUT',
        data: {
          ...voucher,
        },
      }),

      invalidatesTags: (item) =>
        item
          ? [
              { id: item.id, type: 'Vouchers' },
              { id: 'LIST', type: 'Vouchers' },
            ]
          : [],
    }),
    postRevokeVoucherCode: build.mutation<
      { message: string },
      { orgId: string; code: string }
    >({
      query: ({ orgId, code }) => ({
        url: `/${orgId}/vouchers/revoke-voucher-code`,
        method: 'post',
        data: { code: code.replace(/-|\s/g, '') },
      }),
      invalidatesTags: () => ['Vouchers'],
    }),
    getVoucherImportOverview: build.query<
      RowsOverview,
      { orgId: string; voucherId: string }
    >({
      query: ({ orgId, voucherId }) => ({
        url: `/${orgId}/voucher-code-imports/${voucherId}/rows-overview`,
        method: 'get',
      }),
      providesTags: ['Vouchers'],
      transformResponse(res: { rows_overview: RowsOverview }) {
        return res.rows_overview
      },
    }),

    /**
     * ----
     * VOUCHER QUERIES END
     * ---
     */

    getSettings: build.query<
      ServiceSettingsType,
      {
        orgId: string
      }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/settings`,
        method: 'get',
      }),
      providesTags: [{ type: 'ServiceSettings', id: 'LIST' }],
      transformResponse(data: { data: ServiceSettingsType }) {
        return data.data
      },
    }),
    updateSettings: build.mutation<
      ServiceSettingsType,
      Partial<ServiceSettingsType>
    >({
      query: (req) => ({
        url: `/${req.organisation_id}/settings`,
        method: 'put',
        data: req,
      }),

      transformResponse(data: { setting: ServiceSettingsType }) {
        return data.setting
      },
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            serviceApi.util.updateQueryData(
              'getSettings',
              { orgId: req.organisation_id! },
              () => data
            )
          )
          toast.success('Settings updated')
        } catch {
          toast.error('Something went wrong, could not update settings')
        }
      },
    }),

    getReportBuilder: build.query<
      EmailMarketingReportType,
      {
        orgId: string
        start: Date | null
        end: Date | null
        type: string
        segment_id?: string
      }
    >({
      query: ({
        orgId,
        start,
        end,
        type = 'email_campaigns_organisation_report',
        segment_id,
      }) => ({
        url: buildUrl(`/${orgId}/reports/latest`, {
          type,
          from: start && format(start, TimeFormats.BackendYearMonthDay),
          to: end && format(end, TimeFormats.BackendYearMonthDay),
          ...(segment_id && { segment_id }),
        }),
        method: 'GET',
      }),

      transformErrorResponse: async (
        { response }: AxiosError<{ message: string }>,
        _,
        { orgId, start, end, type, segment_id }
      ) => {
        if (response?.status === 403) {
          const resp = await post<{
            data: EmailMarketingReportType
          }>(
            `/${orgId}/reports`,
            {
              type,
              ...(end && {
                to: format(end, TimeFormats.BackendYearMonthDay),
              }),
              ...(start && {
                from: format(start, TimeFormats.BackendYearMonthDay),
              }),
              ...(segment_id && { segment_id }),
            },
            null,
            'service'
          )

          return resp.data
        }
        return response?.data
      },
      providesTags: () => [
        { id: 'LIST', type: 'EmailMarketingCampaignReport' },
      ],
    }),

    storeEmailCampaignEventsExport: build.mutation<
      {
        message: string
        data: { id: string }
      },
      {
        orgId: string
        emailCampaignId: string
      }
    >({
      query: ({ orgId, emailCampaignId }) => ({
        url: `/${orgId}/email-campaign-events-exports`,
        method: 'POST',
        data: {
          email_campaign_id: emailCampaignId,
        },
      }),
    }),

    refreshMarketingReport: build.mutation<
      EmailMarketingReportType,
      {
        orgId: string
        start: Date | null
        end: Date | null
        type: string
        segment_id?: string
      }
    >({
      query: ({
        orgId,
        start,
        end,
        type = 'email_campaigns_organisation_report',
        segment_id,
      }) => ({
        url: buildUrl(`/${orgId}/reports`, {
          type,
          from: start && format(start, TimeFormats.BackendYearMonthDay),
          to: end && format(end, TimeFormats.BackendYearMonthDay),
          ...(segment_id && { segment_id }),
        }),
        method: 'POST',
      }),

      invalidatesTags: () => [
        { id: 'LIST', type: 'EmailMarketingCampaignReport' },
      ],
    }),
  }),
})

export default serviceApi
